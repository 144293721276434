@mixin glowing-text {
  animation: glowing-text-animation 1s ease-in-out infinite alternate;
}
  
@keyframes glowing-text-animation {
  0% {
    text-shadow: 0 0 10px #fff;
    transform: translateY(5px);
  }
  50% {
    text-shadow: 0 0 10px #fff;
    transform: translateY(8px);
  }
  100% {
    text-shadow: 0 0 20px #fff;
    transform: translateY(15px);
  }
}

@mixin hide-scroll-bar{
  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    background-color: transparent;
  }
}