@import 'https://fonts.googleapis.com/css?family=Open+Sans|Roboto:300';
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css");
@import 'Variables';
@import 'UtilityMixins';
@import 'Mixins';
@import 'Button';
@import 'Link';
@import 'Text';


//if we ever become millionaires, putting body and is-loading will come and bite me in the ass,
//but until then, we move

body
{
    font-family: Arial, Helvetica, sans-serif;
    height: 100%;
}

.is-loading
{
    text-align: center;
    font-size: 2em;
    animation: glow 1s ease-in-out infinite;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.glowing-text{
    @include glowing-text;
}

  
@keyframes glow {
    from {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #555555, 0 0 40px #555555, 0 0 50px #555555, 0 0 60px #555555, 0 0 70px #555555;
    }

    to {
        text-shadow: 0 0 20px #fff, 0 0 30px #555557, 0 0 40px #555558, 0 0 50px #555559, 0 0 60px #55555A, 0 0 70px #55555B, 0 0 80px #55555C;
    }
}
