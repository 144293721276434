@import '../../assets/sass/Common.scss';


.Layout
{
    display: flex;
    flex-direction: column;
    height: 100%;

    &-content
    {
        flex-grow: 1;

        &.conversation
        {
            max-height: calc(100% - 122px);
        }
    }

    &-footer{
        display: flex;
    }
}